<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.show', params: {id: company.id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22"/>
      </router-link>
      <div class="mx-actions">
        <router-link class="btn btn-peepz-default" :to="{name: 'faq.edit', params: {'faq_id': faq.id, 'company_id': company.id}}">
          <ph-pencil-simple :size="22"/>
        </router-link>
      </div>
    </div>

    <div class="row">

      <div class="mx-cabinet-main mx-cabinet-faq col-12">
        <div class="mx-card">
          <h5 class="mx-title_border">
            <div>
              <span>{{ $t('faq.welcome_text') }}</span>
            </div>
          </h5>
          <div style="min-height: 45px">
            <h4>{{ faq.welcome_text }}</h4>
          </div>
        </div>
        <div class="mx-card">
          <h5 class="mx-title_border">
            <div>
              <span>{{ $t('faq.question.title') }}</span>
            </div>


            <div class="input-group mx-form-faq mx-form-faq__search">
              <input ref="mxfocus" v-model="search" type="text" class="form-control" :placeholder="$t('faq.search')">
              <button @click="searchClear()" class="btn btn-peepz-accept" type="button">
                <ph-x :size="22" weight="light"/>
              </button>
            </div>


<!--            <router-link :to="{name: 'question.create', params: {'company_id': company.id, 'faq_id': faq.id}}" class="btn btn-peepz-success">-->
<!--              <ph-plus-circle :size="22" />-->
<!--              <span v-if="!mobile">{{ $t('faq.question.button') }}</span>-->
<!--            </router-link>-->


            <b-dropdown id="dropdown-right" right text="Frage" size="sm" variant="btn btn-peepz-success" >
              <router-link :to="{name: 'question.create', params: {'company_id': company.id, 'faq_id': faq.id}}" class="dropdown-item">
                <span>{{ $t('faq.question.button') }}</span>
              </router-link>
              <router-link :to="{name: 'question.csv.import', params: {'company_id': company.id, 'faq_id': faq.id}}" class="dropdown-item">
                <span>{{ $t('faq.question.csv.button_import') }}</span>
              </router-link>

<!--              <b-dropdown-item href="#" @click="scvQuestionsImportModal(true)" class="mx-logout">-->
<!--                <span>{{ $t('faq.question.csv.button_import') }}</span>-->
<!--              </b-dropdown-item>-->
            </b-dropdown>


          </h5>
          <div class="mx-faq-questions" ref="mxFaq" :style="`max-height: calc(100vh - ${height}px)`">
            <router-link :to="{name: 'question.update', params: {'company_id': company.id, 'question_id': question.id, 'faq_id': faq.id}}" tag="div" class="mx-faq-question" v-for="question in filterQuestions">
              <div class="mx-faq-question-left">
                <span class="mx-faq-question__title">{{ question.title }}</span>
              </div>
              <div class="mx-faq-question-right">
                <div class="mx-faq-question__text">
                  <span v-html="brs(question.text)"></span>
                </div>
                <div v-if="question.notice  !== null" class="mx-faq-question__notice">
                  <span>{{ question.notice }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

    </div>

    <!--  Modal  -->
    <b-modal ref="Delete" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
     <div class="mx-modal-prompt">
       <h3 class="mx-modal-prompt__title">{{ $t('company.prompt.company.title') }}</h3>
       <strong class="mx-modal-prompt__subtitle">{{ $t('company.prompt.company.subtitle') }}</strong>
       <div class="mx-modal-prompt__buttons">
         <button type="button" :disabled="button_disabled" class="btn btn-peepz-danger" @click="elementDelete">{{ $t('company.delete') }}</button>
         <button type="button" :disabled="button_disabled" class="btn btn-peepz-default" @click="DeleteModal(false)">{{ $t('company.abort') }}</button>
       </div>
     </div>
    </b-modal>

    <!--  Modal  -->
    <b-modal ref="scvQuestionsImport" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="mx-modal-prompt">
        <h3 class="mx-modal-prompt__title">{{ $t('faq.question.csv.prompt.title') }}</h3>
        <strong class="mx-modal-prompt__subtitle">{{ $t('faq.question.csv.prompt.subtitle') }}</strong>
        <input type="file" accept="text/csv" style="margin: 15px 0 50px 0;" v-on:change="csvFileChange">
        <div class="mx-modal-prompt__buttons">
          <button type="button" :disabled="button_disabled || csvFile === null" class="btn btn-peepz-success" @click="scvQuestionsSave">{{ $t('faq.question.csv.load') }}</button>
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-default" @click="scvQuestionsImportModal(false)">{{ $t('company.abort') }}</button>
        </div>
      </div>
    </b-modal>

  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";


import {
  PhBuildings,
  PhIdentificationCard,
  PhPlusCircle,
  PhCaretLeft,
  PhPencilSimple,
  PhNotebook,
  PhMapPin,
  PhMapPinLine,
  PhQuestion,
  PhTrashSimple,
  PhFloppyDisk,
  PhX
} from "phosphor-vue";

import axios from "axios";

export default {
  components: {
    CabinetPage,
    PhBuildings,
    PhIdentificationCard,
    PhPlusCircle,
    PhCaretLeft,
    PhPencilSimple,
    PhNotebook,
    PhMapPin,
    PhMapPinLine,
    PhTrashSimple,
    PhQuestion,
    PhFloppyDisk,
    PhX
  },
  data() {
    return {
      mobile: false,
      button_disabled: false,
      preloader: true,
      csvFile: null,
      search: '',
      height: 300,
      company: {},
      main_address: {},
      user: {},
      faq: {},
      questions: []
    }
  },
  computed: {
    filterQuestions() {
      return this.questions.filter(question => {
        return question.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.company.id = this.$route.params.company_id
    this.getUser()
    this.getFaqs()
  },
  methods: {

    brs(text) {
      return text.replace(/\n/g, '<br />')
    },

    scvQuestionsSave() {
      let formData = new FormData()
      formData.append('id', this.faq.id)
      formData.append('file', this.csvFile)

      const data = {
        id: this.faq.id,
        file: this.csvFile
      }

      axios.post(`question/csv/import/${this.faq.id}`, formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log(response.data);
      })

      this.scvQuestionsImportModal(false)
    },
    csvFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.csvFile = files[0]
    },
    scvQuestionsImportModal(boll) {
      if (boll) {
        this.$refs['scvQuestionsImport'].show()
      } else {
        this.csvFile = null
        this.$refs['scvQuestionsImport'].hide()
      }
    },
    sHeight() {
      const element_top = parseInt(this.$refs.mxFaq.getBoundingClientRect().top)
      this.height = (element_top + 50)
    },
    searchClear() {
      this.search = ''
    },
    save() {
      this.$store.dispatch('faqEdit', this.faq).then(() => {
        console.log('access');
      })
    },
    DeleteModal(boll) {
      if (boll) {
        this.$refs['Delete'].show()
      } else {
        this.$refs['Delete'].hide()
      }
    },
    elementDelete() {},
    preload() {
      this.preloader = false;
    },
    getUser() {
      this.user = this.$store.getters.user
    },
    getFaqs() {
      this.$store.dispatch('faqs', this.$route.params.company_id).then(() => {
        this.faq = this.$store.getters.faqs.find(faq => faq.company_id === parseInt(this.$route.params.company_id))
        this.questions = this.faq.questions
        this.preload()
        setTimeout(this.sHeight, 300)
        this.$nextTick(() => this.$refs.mxfocus.focus())
      })
    }
  }
}
</script>
